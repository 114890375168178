import React from 'react'
import { useInView } from "react-intersection-observer"

const AnimateRight = ({ threshold = 0.15, children }) => {
  const [ref, inView] = useInView({ threshold })

  return (
    <div
      style={{
        transition: "opacity 1000ms, transform 1500ms",
        opacity: inView ? 1 : 0,
        transform: `translateX(${inView ? 0 : -100}px)`,
      }}
      ref={ref}
    >
    {children}
    </div>
  )
}

export default AnimateRight