import { Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import HeartLogo from "../images/heart_logo_cropped.png"
import Section from "./Section"
import Logo from "../images/logo_justText.png"
import SlideUp from "./animations/slideUp"
const useStyles = makeStyles(theme => ({
  root: {},
  heroLogo: {
    textAlign: "center",
    placeContent: "center",
    margin: "0 !important",
    width: "400px",
    padding: "0 0 10px 0",
  },
  heroLogoHeart: {
    textAlign: "center",
    placeContent: "center",
    margin: "0 !important",
    width: "80px",
    // padding: '0 0 10px 0'
  },
}))

export default function About() {
  const classes = useStyles()
  return (
    <Section id={`aboutRajaaRani`} colorBG={"#FDF1CC"}>
      <Grid
        container
        justify="center"
        direction="column"
        className={classes.root}
      >
        <Grid item>
          <SlideUp>
            <img
              className={classes.heroLogoHeart}
              src={HeartLogo}
              alt="rajaa weds rani"
            />
            <br />
            <img
              className={classes.heroLogo}
              src={Logo}
              alt="rajaa weds rani"
            />
            <br />
          </SlideUp>
        </Grid>
        <Grid item>
          <SlideUp>
            <Typography>
              ‘RajaRani’ is a team of hardworking people, with a mission, and a
              promise. 'Team RajaRani.com’ is dedicated to provide you with your
              life partner search experience and believes that no other
              matchmaking service can provide you with the value that
              RajaRani.com can keeping in view that this involves all the
              religious teachings. So much so, that our service is endorsed by
              an unconditional guarantee.
            </Typography>
            <br />
            <Typography>
              Please take some time to get to know us better by registering
              yourself today. Perhaps the right information will help you decide
              if we are the appropriate solution for you or somebody you know.
            </Typography>
            <Typography>The mandate to 'Team RajaRani is clear</Typography>
            <Typography>
              Understands the requirements and concerns of single Muslims around
              the globe after researching tirelessly and analyzing all the data
            </Typography>
            <Typography>
              Our users have the complete control as the interface is user
              friendly and all the features can help them look into, filter and
              then contact the right match
            </Typography>
          </SlideUp>
        </Grid>
      </Grid>
    </Section>
  )
}
