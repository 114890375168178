import React, { useEffect, useState, useRef } from "react"
import { useInView } from "react-intersection-observer"

const AnimateMoveDown = ({ threshold = 0.2, alt, rotate, ...imgProps }) => {
  const [ref, inView] = useInView({ threshold })
  const [deg, setDeg] = useState(0)
  const [scrollY, setScrollY] = useState(0)
  function logit() {
    setScrollY(window.pageYOffset)
  }

  useEffect(() => {
    if (inView) {
      function watchScroll() {
        window.addEventListener("scroll", logit)
      }
      watchScroll()
      return () => {
        window.removeEventListener("scroll", logit)
      }
    }
  }, [inView])

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevCount = usePrevious(scrollY)

  useEffect(() =>{
      if(scrollY>prevCount){
        setDeg(d => d+0.3)
      }else{
        setDeg(d => d-0.1)
    }
  },[scrollY, prevCount])

  return (
    <img
      style={{
        willChange: "transform",
        transform: `translate3d(0px, ${deg}px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${rotate}deg) skew(0deg)`,
        transformStyle: "preserve-3d",
      }}
      {...imgProps}
      alt={alt}
      ref={ref}
    />
  )
}

export default AnimateMoveDown
